<template>
  <div
    :id="`chartdiv${index}`"
    style="width: 100%; height: 355px; direction: ltr"
    :class="'piechart' + lang"
  ></div>
</template>
<script>
import * as am4core from '@amcharts/amcharts4/core';
am4core.addLicense('CH351911686');
import * as am4charts from '@amcharts/amcharts4/charts';
export default {
  name: 'MonitoringReportPie',
  props: ['data', 'index'],
  data() {
    return {
      lang: this.$store.state.Global.lang,
      chart: null
    };
  },
  mounted() {
    this.setCharts();
  },
  beforeDestroy(){
    if (this.chart) {
      this.chart.dispose();
      this.chart = null;
    }
  },
  methods: {
    setCharts() {
      const chart = am4core.create(`chartdiv${this.index}`, am4charts.PieChart);
      let connected = 0;
      let disconnected = 0;
      for (const i of this.data) {
        connected += i.connected.length;
        disconnected += i.disconnected.length;
      }
      chart.data = [
        {
          type: this.$t('Installation Success'),
          value: connected,
          color: am4core.color('#4caf50')
        },
        {
          type: this.$t('Installation Fail'),
          value: disconnected,
          color: am4core.color('#ED1C24')
        }
      ];
      const pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = 'value';
      pieSeries.dataFields.category = 'type';
      pieSeries.slices.template.propertyFields.fill = 'color';
      pieSeries.labels.template.disabled = true;

      chart.innerRadius = am4core.percent(70);
      chart.legend = new am4charts.Legend();
      chart.legend.valueLabels.template.minWidth = 40;
      chart.legend.valueLabels.template.align = 'right';
      chart.legend.valueLabels.template.textAlign = 'end';
      const label = pieSeries.createChild(am4core.Label);
      label.text = '[bold]{values.value.sum}[/bold]';
      label.horizontalCenter = 'middle';
      label.verticalCenter = 'bottom';
      label.fontSize = 24;
      const label2 = pieSeries.createChild(am4core.Label);
      label2.text =
        this.$store.state.Global.lang === 'en' ? 'Total devices' : 'סה"כ בקרים';
      label2.horizontalCenter = 'middle';
      label2.verticalCenter = 'top';
      label2.fontSize = 16;
      chart.legend.valueLabels.template.text = '{value.value}';
      this.chart = chart;
    }
  },
  computed: {
    language() {
      return this.$store.state.Global.lang;
    }
  },
  watch: {
    language(newVal) {
      this.lang = newVal;
      this.setCharts();
    }
  }
};
</script>
<style scoped>
</style>
